var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"post-list-wrap"},_vm._l((_vm.list),function(item,index){return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(item.loading),expression:"item.loading"}],key:index},[_c('div',{staticClass:"post-item",style:({
            'background': _vm.getColor(index, 'background')
        })},[_c('div',{staticClass:"post-name",style:({
                'background': _vm.getColor(index, 'subBackground'),
                'color': _vm.getColor(index, 'color'),
            })},[_vm._v(_vm._s(item.postName)+" ")]),_vm._m(0,true),_vm._l((item.children),function(it,idx){return _c('div',{key:idx,staticClass:"push-item"},[(it.noticeType === 3)?_c('div',{staticClass:"text"},[_c('span',[_vm._v("累计扣分超过")]),_c('el-input',{staticClass:"txt-score",attrs:{"placeholder":"请输入分数","clearable":""},on:{"input":function($event){return _vm.limitIptNumber(it)}},model:{value:(it.accumulativeScore),callback:function ($$v) {_vm.$set(it, "accumulativeScore", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"it.accumulativeScore"}}),_c('span',[_vm._v("通知")])],1):_c('div',{staticClass:"text"},[_vm._v(_vm._s(it.title))]),_c('div',[_c('el-switch',{attrs:{"active-value":1,"inactive-value":2,"active-color":_vm.getColor(index, 'color')},on:{"change":function($event){return _vm.handleChange($event, it)}},model:{value:(it.pushFlag),callback:function ($$v) {_vm.$set(it, "pushFlag", $$v)},expression:"it.pushFlag"}}),_c('el-switch',{attrs:{"active-value":1,"inactive-value":2,"active-color":_vm.getColor(index, 'color')},on:{"change":function($event){return _vm.handleChange($event, it)}},model:{value:(it.msgFlag),callback:function ($$v) {_vm.$set(it, "msgFlag", $$v)},expression:"it.msgFlag"}})],1)])})],2)])}),0)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"notice-title"},[_c('div',{staticClass:"text"},[_vm._v("推送通知")]),_c('div',{staticClass:"text",staticStyle:{"margin-left":"63px"}},[_vm._v("短信通知")])])
}]

export { render, staticRenderFns }