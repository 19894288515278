<template>
    <div class="notice-manage">
        <global-page-back detailPageTitle="通知管理" @handlerGlobalType="handlerGlobalType"></global-page-back>
        <div class="notice-content">
            <div class="button-group">
                <xk-button
                    :loading="whiteListLoading"
                    v-has-permi="['moralmanage:moraldocument:notice:whitelist']"
                    type="primary"
                    margin-left="0"
                    @click="configWhiteList"
                >
                    配置白名单
                </xk-button>
                <xk-button
                    :loading="noDisturbLoading"
                    v-has-permi="['moralmanage:moraldocument:notice:ignore']"
                    backgroundColor="#668cff"
                    color="#ffffff"
                    class="filter-button"
                    @click="configNoDisturb"
                >
                    配置免打扰
                </xk-button>
            </div>
            <el-scrollbar
                v-has-permi="['moralmanage:moraldocument:notice:list']"
                v-loading="loading"
                style="height: calc(100vh - 200px);"
                wrap-style="overflow-x:hidden;"
            >
                <post-list
                    :list="list"
                    @change="handleSave"
                ></post-list>
            </el-scrollbar>
        </div>
        <dialog-wrapper
            :dialog-obj="dialogObj"
            @handleClose="handleTreeDialogClose"
            class="simple-dialog-form"
        >
            <sel-tree3
                :loading="btnLoading"
                :key="dialogObj.treeKey"
                ref="teacherSelTree"
                :selTreeOpt="selTreeOpt"
                @setTreeData="handleSetTreeData"
                @cancel="handleTreeDialogClose"
            />
        </dialog-wrapper>
    </div>
</template>

<script>
import { mapState } from "vuex";
import { FilterData, DialogWrapper } from "common-local";
import PostList from "@/components/scrollWrapper/SManagerEduMoralAssessment/NoticeManage/PostList";
import SelTree3 from "@/components/scrollWrapper/Sub/SelTree3";
import GlobalPageBack from '../Sub/GlobalPageBack/index.vue';

export default {
    name: "NoticeManage",
    components: {
        FilterData,
        DialogWrapper,
        PostList,
        SelTree3,
        GlobalPageBack
    },
    data() {
        return {
            whiteListLoading: false,
            noDisturbLoading: false,
            btnLoading: false,
            loading: true,
            list: [],
            dialogObj: {
                title: "",
                width: "600px",
                dialogVisible: false,
                treeKey: 0,
            },
            configData: {
                oirList: [],
                type: "",
            },
            selTreeOpt: {
                data: [], // 树形数据
                showData: [], // 回显数据
                status: "multiple", // single单选 multiple
                maxLen: "", // 限制最多选择个数
            },
        };
    },
    computed: {
        ...mapState({
            schoolId: (state) => state.schoolId,
        }),
    },
    created() {
        this.getList();
        this.getTeacherList();
    },
    methods: {
        /**
         * @Description: 获取通知管理列表
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 韩熙昊
         * @Date: 2023/3/28 9:51
         */
        getList() {
            const params = {
                schoolId: this.schoolId,
            };
            this.loading = true;
            this._fet("/school/schoolMoralNoticeConfig/getListInit", params)
                .then((res) => {
                    if (res.data.code === "200") {
                        if (res.data.data === true) {
                            setTimeout(() => {
                                this.getList();
                            }, 1000)
                        }
                        res.data.data.forEach((item) => {
                            item.sort = 3
                            if (item.postName === '家长' && (!item.postId || item.postId === '1')) {
                                item.postId = '1'
                                item.sort = 1
                            }
                            if (item.postName === '班主任' && (!item.postId || item.postId === '1')) {
                                item.postId = '2'
                                item.sort = 2
                            }
                        })
                        this.list = this.arrGroupBy(res.data.data.sort((a, b) => a.sort - b.sort));
                    } else if (res.data && res.data.msg) {
                        this.$message.error(res.data.msg);
                    }
                    this.loading = false;
                })
                .catch(() => {
                    this.loading = false;
                });
        },
        /**
         * @Description: 根据职务id处理数据
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 韩熙昊
         * @Date: 2023/3/28 10:21
         */
        arrGroupBy(arr) {
            let map = {};
            let myArr = [];
            let typeList = {
                1: "批评通知",
                2: "表扬通知",
                3: "累计扣分通知",
            };
            for (let i = 0; i < arr.length; i++) {
                arr[i].title = typeList[+arr[i].noticeType];
                if (!map[arr[i].postId]) {
                    myArr.push({
                        postId: arr[i].postId,
                        postName: arr[i].postName,
                        schoolId: arr[i].schoolId,
                        children: [arr[i]],
                    });
                    map[arr[i].postId] = arr[i].postId;
                } else {
                    for (let j = 0; j < myArr.length; j++) {
                        if (arr[i].postId === myArr[j].postId) {
                            myArr[j].children.push(arr[i]);
                            break;
                        }
                    }
                }
            }
            myArr.forEach((item) => {
                item.children.sort((a, b) => {
                    return a.noticeType - b.noticeType;
                });
            });
            return myArr;
        },
        /**
         * @Description: 编辑通知
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 韩熙昊
         * @Date: 2023/3/28 11:31
         */
        handleSave(item) {
            const params = {
                id: item.id,
                pushFlag: item.pushFlag,
                msgFlag: item.msgFlag,
                accumulativeScore: item.accumulativeScore,
            };
            this.loading = true;
            this._fet("/school/schoolMoralNoticeConfig/save", params)
                .then((res) => {
                    if (res.data.code === "200") {
                        this.$message.success("修改成功");
                        this.getList();
                    } else if (res.data && res.data.msg) {
                        this.$message.error(res.data.msg);
                    }
                    this.loading = false;
                })
                .catch(() => {
                    this.loading = false;
                });
        },
        /**
         * @Description: 配置白名单
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 韩熙昊
         * @Date: 2023/3/28 14:03
         */
        async configWhiteList() {
            this.whiteListLoading = true
            await this.getTeacherConfig(1);
            this.dialogObj.dialogVisible = true;
            this.dialogObj.treeKey++
            this.dialogObj.title = "配置白名单";
            setTimeout(() => {
                this.whiteListLoading = false
            }, 200)
        },
        /**
         * @Description: 配置免打扰
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 韩熙昊
         * @Date: 2023/3/28 14:03
         */
        async configNoDisturb() {
            this.noDisturbLoading = true
            await this.getTeacherConfig(2);
            this.dialogObj.dialogVisible = true;
            this.dialogObj.treeKey++
            this.dialogObj.title = "配置免打扰";
            setTimeout(() => {
                this.noDisturbLoading = false
            }, 200)
        },
        /**
         * @Description: 获取配置教师列表
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 韩熙昊
         * @Date: 2023/3/28 15:39
         */
        async getTeacherConfig(type) {
            await this._fet(
                "/school/schoolMoralNoticeTeacherConfig/listByCondition",
                {
                    schoolId: this.schoolId,
                    type: type,
                },
            ).then((res) => {
                if (res.data.code === "200") {
                    this.configData.type = type;
                    this.configData.oirList = res.data.data;
                    this.selTreeOpt.showData = res.data.data.map((i) => i.teacherId)
                } else if (res.data && res.data.msg) {
                    this.$message.error(res.data.msg);
                }
            })
        },
        /**
         * @Description: 查询教师列表
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 韩熙昊
         * @Date: 2023/3/28 14:18
         */
        getTeacherList() {
            this._fet("/school/schoolOrgan/getIssueScopeByAccess", {
                schoolId: this.schoolId,
                applyPerson: "1",
            }).then((res) => {
                if (res.data.code === "200") {
                    this.selTreeOpt.data = res.data.data;
                }
            });
        },
        /**
         * @Description: 保存弹窗配置
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 韩熙昊
         * @Date: 2023/3/30 17:49
         */
        handleSetTreeData(data) {
            let departmentalStaffList = []
            this.getDepartmentalStaff(data.data, departmentalStaffList, "teacher")
            departmentalStaffList = [...new Set(departmentalStaffList)]
            let teaList = [],
                teaListDel = [];
            // 新添加的白名单
            departmentalStaffList.forEach((item) => {
                let index = this.configData.oirList.findIndex(
                    (i) => i.teacherId === item.id,
                );
                if (index === -1) {
                    teaList.push(item);
                }
            });
            // 移除的白名单
            this.configData.oirList.forEach((item) => {
                let index = departmentalStaffList.findIndex(
                    (i) => i.id === item.teacherId,
                );
                if (index === -1) {
                    teaListDel.push(item);
                }
            });
            this.btnLoading = true
            this._fet('/school/schoolMoralNoticeTeacherConfig/verification',{
                schoolId: this.schoolId,
                type: this.configData.type === 1 ? 2 : 1,
                teaList: teaList.map(i => i.id),
            }).then((res) => {
                if (res.data.code === '200') {
                    let params = {
                        schoolId: this.schoolId,
                        type: this.configData.type,
                        teaList: teaList.map(i => i.id),
                        teaListDel: teaListDel.map(i => i.teacherId),
                    };
                    if (res.data.data && res.data.data.length > 0) {
                        let message = []
                        teaList.forEach((item) => {
                            res.data.data.forEach(it => {
                                if (item.id === it.teacherId) {
                                    message.push(item.name)
                                }
                            })
                        })
                        let typeData = {
                            2: '白名单',
                            1: '免打扰'
                        }[+this.configData.type]
                        message = message.toString() + `老师在${typeData}中，点击保存后会将其移除${typeData}，是否继续?`
                        this.$confirm(message, '提示', {
                            confirmButtonText: '确定',
                            cancelButtonText: '取消',
                            type: 'warning'
                        }).then(() => {
                            this.saveTeacherConfig(params)
                        }).catch(() => {
                            this.btnLoading = false
                        })
                    } else {
                        this.saveTeacherConfig(params)
                    }
                }
            }).catch(() => {
                this.btnLoading = false
            })
        },
        saveTeacherConfig(params) {
            this._fet("/school/schoolMoralNoticeTeacherConfig/save", params).then((res) => {
                if (res.data.code === "200") {
                    this.$message.success("保存成功");
                    this.handleTreeDialogClose();
                } else if (res.data && res.data.msg) {
                    this.$message.error(res.data.msg);
                }
            }).finally(() => {
                this.btnLoading = false;
            })
        },
        /**
         * @Description: 关闭弹窗
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 韩熙昊
         * @Date: 2023/3/30 17:49
         */
        handleTreeDialogClose() {
            this.dialogObj.dialogVisible = false;
        },
        /**
         * @Description: 获取选中的教师id
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 韩熙昊
         * @Date: 2023/3/30 17:49
         */
        getDepartmentalStaff(list, departmentalStaffList, key) {
            list.forEach((item) => {
                if (item.key === key) {
                    departmentalStaffList.push(item)
                }
                if (item.children && item.children.length > 0) {
                    this.getDepartmentalStaff(item.children, departmentalStaffList, key)
                }
            })
        },
        /**
         * @Description: 返回
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 韩熙昊
         * @Date: 2023/3/28 10:44
         */
         handlerGlobalType() {
            this.$emit("changeComp", {
                name: "AssessmentList",
            });
        },
    },
};
</script>

<style scoped lang="scss">
.notice-manage {
    .back-nav {
        border-bottom: 1px solid #edf0f2;
    }

    .button-group {
        padding: 16px 20px 8px 20px;

        .filter-button {
            border-color: transparent;
        }

        .filter-button:hover {
            filter: brightness(1.1);
        }
    }

    .notice-content {
        border-radius: 4px;
        overflow: hidden;
        margin-top: 10px;
        background-color: #fff;
    }
}

.simple-dialog-form {
    ::v-deep .el-dialog {
        border-radius: 8px;
        .el-dialog__header {
            background-color: #EBF1FA;
            border-top-right-radius: 8px;
            border-top-left-radius: 8px;
        }
    }
}
</style>
