<template>
    <div class="post-list-wrap">
        <div v-loading="item.loading" v-for="(item, index) in list" :key="index">
            <div class="post-item" :style="{
                'background': getColor(index, 'background')
            }">
                <div class="post-name" :style="{
                    'background': getColor(index, 'subBackground'),
                    'color': getColor(index, 'color'),
                }">{{ item.postName }}
                </div>
                <div class="notice-title">
                    <div class="text">推送通知</div>
                    <div class="text" style="margin-left: 63px">短信通知</div>
                </div>
                <div
                    v-for="(it, idx) in item.children"
                    :key="idx"
                    class="push-item"
                >
                    <div v-if="it.noticeType === 3" class="text">
                        <span>累计扣分超过</span>
                        <el-input class="txt-score" v-model.trim="it.accumulativeScore" placeholder="请输入分数" @input="limitIptNumber(it)" clearable></el-input>
                        <span>通知</span>
                    </div>
                    <div v-else class="text">{{ it.title }}</div>
                    <div>
                        <el-switch
                            v-model="it.pushFlag"
                            :active-value="1"
                            :inactive-value="2"
                            :active-color="getColor(index, 'color')"
                            @change="handleChange($event, it)"
                        ></el-switch>
                        <el-switch
                            v-model="it.msgFlag"
                            :active-value="1"
                            :inactive-value="2"
                            :active-color="getColor(index, 'color')"
                            @change="handleChange($event, it)"
                        ></el-switch>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {debounce} from 'common-local'
export default {
    name: "PostList",
    props: {
        list: {
            type: Array,
            default() {
                return []
            }
        }
    },
    data() {
        return {
            colorList: [
                {
                    background: "#f2f7fc",
                    subBackground: "#d7eafc",
                    color: "#3C7FFF"
                },
                {
                    background: "#edfaf8",
                    subBackground: "#c4f5ed",
                    color: "#04CCAB"
                },
                {
                    background: "#faf5f2",
                    subBackground: "#f7e0d2",
                    color: "#E6702C"
                },
                {
                    background: "#f9f5ff",
                    subBackground: "#ebdefc",
                    color: "#A76AFC"
                },
            ]
        }
    },
    methods: {
        getColor(index, type) {
            return this.colorList[index % 4][type]
        },
        handleChange(data, item) {
            this.$emit("change", item)
        },
        /**
         * @Description: 获取通知管理列表
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 王园园
         * @Date: 2023/7/18 18:30
         */
        // 限制输入带一位小数
        limitIptNumber: debounce(function (it){
         var str = it.accumulativeScore
        //  let len1 = str.substr(0, 1)
        //  let len2 = str.substr(1, 1)
        //  let len3 = str.substr(2, 1)
         // 如果第一位是0，第二位不是点，就用数字把0替换掉
        //  if (str.length > 1 && len1 == 0 && len2 != ".") {
        //     str = str.substr(1, 1)
        //  }
         str = str.replace(/^\D*([0-9]\d*\.?\d{0,0})?.*$/, '$1')
         str = str.replace(/[^0-9]/, '')
         if (str>100) {
            this.$message.error('请输入100以内的数字')
            return
         }
         if (str=='') {
            this.$message.warning('请输入数字')
            return
         }
         
         it.accumulativeScore = str
         this.$emit("change", it)
        //  console.log("分数的",it.accumulativeScore)
        },500)
    }
}
</script>

<style scoped lang="scss">
.post-list-wrap {
    display: flex;
    flex-wrap: wrap;
    padding-left: 12px;
    .post-item {
        position: relative;
        width: 536px;
        height: 202px;
        background: #F2F7FC;
        border-radius: 8px;
        margin: 8px;
        padding: 28px 40px 20px 25px;
        box-sizing: border-box;

        .text {
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #2B2F33;
            .txt-score{
                width: 112px;
                height: 36px !important;
                border: none;
                padding: 5px 8px;
            }
        }

        .post-name {
            position: absolute;
            left: 0;
            top: 0;
            width: 88px;
            height: 36px;
            text-align: center;
            line-height: 36px;
            background: #D7EAFC;
            border-radius: 8px 0px 8px 0px;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: bold;
        }

        .notice-title {
            display: flex;
            justify-content: flex-end;
        }

        .push-item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 13px;
            height: 32px;

            .score-select {
                width: 56px;
                background: #FFFFFF;
                border-radius: 4px;
                margin: 0 10px;

                ::v-deep .el-input__inner {
                    height: 32px !important;
                    border: none;
                    padding: 10px 8px;
                    box-sizing: border-box;
                }
            }

            .el-switch {
                width: 56px;
                justify-content: center;
            }

            .el-switch + .el-switch {
                margin-left: 63px;
            }
        }
    }
}
</style>
